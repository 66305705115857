<template>
  <b-dropdown
    id="filter-project-role"
    size="sm"
    text="Project Status"
    variant="primary"
    class="table-filter"
    right
  >
    <template v-for="(stage, s) in systemProjectStages">
      <b-dropdown-item
        :key="s"
        href="javascript:void(0)"
        @click.native.capture.stop="onToggleFilter(stage)"
      >
        <div
          class="d-flex align-items-center justify-content-start"
        >
          <div style="width:18px">
            <i
              v-show="filteredStages.includes(stage.id)"
              class="fas fa-check font-size-xs mr-2"
            />
          </div>

          {{ stage.name }}
        </div>
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import {
  mapGetters
} from "vuex";

export default {
  name: "ProjectStageFilter",

  props: {
    filteredStages: { type: Array, required: true }
  },

  methods: {
    onToggleFilter: function(stage) {
      if (this.filteredStages.includes(stage.id)) {
        this.$emit('onFilterChange',this.filteredStages.filter(function(appliedStage) { return appliedStage != stage.id } ) );
      } else {
        let newFilteredStages = JSON.parse(JSON.stringify(this.filteredStages));
        newFilteredStages.push(stage.id);
        this.$emit('onFilterChange',newFilteredStages);
      }
    }
  },

  computed: {
    ...mapGetters([
      "systemProjectStages"
    ])
  }
}
</script>

<style>
  #filter-project-role .dropdown-item {
    background: transparent !important;
    color: #181C32 !important
  }
</style>