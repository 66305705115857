<template>
  <tr
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <td class="px-2 py-3 d-table-cell">
      <a
        :href="(project.system_project_stage_id != 6) ? projectViewLink : null"
        class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
      >
        {{ project.name }}
      </a>
      <span
        class="text-muted font-weight-bold text-muted d-block"
        >{{ projectUrl }}</span
      >
    </td>
    <td class="px-2 py-6 d-table-cell font-size-sm">
      {{ project.key }}
    </td>
    <td class="px-2 py-6 d-table-cell font-size-sm text-center">
      <ProjectStageLabel :project="project"></ProjectStageLabel>
    </td>
    <td class="px-2 py-6 d-table-cell text-center">
      <span class="text-dark-65 font-weight-bold d-block font-size-sm">
        {{
          project.invited_users_count + project.subscribed_users_count
        }}
      </span>
    </td>
    <td class="px-2 py-6 d-table-cell">
      <span class="text-dark-65 font-weight-bold d-block font-size-sm">
        {{ project.language.name }}
      </span>
    </td>
    <td class="px-2 py-6 d-table-cell">
      <span :class="projectDate.class" class="font-size-sm">
        <span>{{ projectDate.prefix }}</span>
        <GenericDatetimeDisplay
          v-if="project.project_stage.name =='Draft'"
          :long="false"
          :date="project.starts_on"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
        <GenericDatetimeDisplay
          v-else
          :long="false"
          :date="project.ends_on"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
      </span>
    </td>
    <td class="px-2 py-6 d-table-cell">
      <span :class="adminDate.class" class="font-size-sm">
        <span>{{ adminDate.prefix }}</span>
        <GenericDatetimeDisplay
          :long="false"
          :date="project.admin_end_date"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
      </span>
    </td>
    <td class="px-2 py-4 text-right">
      <div v-if="isHovering">
        <CreateTemplateFromProject
          v-if="userCanCreate() && project.system_project_stage_id != 6"
          @click="openCreateTemplateModal(project)"
        />
        <NavigateToProjectDashboard
          v-if="project.system_project_stage_id != 6"
          :link="projectViewLink"
        />
        <EditProject
          v-if="userCanEdit() && project.system_project_stage_id != 6"
          :link="projectEditLink"
        />
      </div>
    </td>

    <CreateProjectTemplate
      :project="project"
      ref="createProjectTemplateModal"
    >
    </CreateProjectTemplate>

  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectStageLabel from "@/view/components/ProjectStageLabel.vue";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"
import CreateTemplateFromProject from "@/view/components/table-actions/CreateTemplateFromProject";
import NavigateToProjectDashboard from "@/view/components/table-actions/NavigateToProjectDashboard";
import EditProject from "@/view/components/table-actions/EditProject";
import CreateProjectTemplate from "@/view/components/modals/CreateProjectTemplate";

export default {
  name: "ProjectRow",

  props: {
    project: { type: Object, required: true },
    client: { type: Object, required: true },
  },

  data() {
    return {
      isHovering: false
    }
  },

  components: {
    ProjectStageLabel,
    GenericDatetimeDisplay,
    CreateTemplateFromProject,
    NavigateToProjectDashboard,
    EditProject,
    CreateProjectTemplate
  },

  methods: {
    openCreateTemplateModal: function() {
      this.$refs.createProjectTemplateModal.$refs.modal.show();
    },
    userCanCreate: function() {
      return this.authUserIsSystemAdmin || this.authUserIsClientAdmin;
    },
    userCanEdit: function() {
      return this.authUserIsSystemAdmin || this.authUserIsClientAdmin;
    },
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "clientInfo"
    ]),
    authUserIsSystemAdmin: function() {
      return this.currentUser.system_admin_role_id == 1;
    },
    authUserIsClientAdmin: function() {
      const self = this;
      let clientEntries = this.currentUser.clients.filter(function(client) {
        return client.id == self.clientInfo.id;
      });

      if (clientEntries.length == 0) {
        return false;
      } else {
        let clientEntry = clientEntries[0];
        if (clientEntry.pivot.system_client_role_id == 1) {
          return true;
        } else {
          return false;
        }
      }
    },
    projectViewLink: function() {
      return this.projectUrl + "/#/project/view";
    },
    projectEditLink: function() {
      return this.projectUrl + "/#/project/edit/" + this.project.id;  
    },
    projectUrl() {
      let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";

      if (this.client.custom_domain == null) {
        return protocol + this.project.key.toLowerCase() + "-" + this.client.key + "." + process.env.VUE_APP_ADMIN_URL_SUFFIX;  
      } else {
        return protocol + this.project.key.toLowerCase() + "." + "admin" + "." + this.client.custom_domain;
      }
    },
    projectDate() {
      let projectStage = this.project.project_stage.name;
      let prefix = "";
      let calcClass = "";

      if (projectStage == "Draft") {
        prefix = `${this.$t("GENERAL.PREFIX.OPENS_ON")}` + ": ";
        calcClass = "warning";
      } else if (projectStage == "Recruitment") {
        prefix = `${this.$t("GENERAL.PREFIX.CLOSES_ON")}` + ": ";
        calcClass = "success";
      } else if (projectStage == "Active") {
        prefix = `${this.$t("GENERAL.PREFIX.CLOSES_ON")}` + ": ";
        calcClass = "success";
      } else if (projectStage == "Review") {
        prefix = `${this.$t("GENERAL.PREFIX.CLOSED_ON")}` + ": ";
        calcClass = "muted";
      } else if (projectStage == "Closed" || projectStage == "Archived") {
        prefix = `${this.$t("GENERAL.PREFIX.CLOSED_ON")}` + ": ";
        calcClass = "muted";
      }

      return {
        prefix: prefix,
        class: "text-" + calcClass + " font-weight-bold d-block"
      };
    },
    adminDate() {
      let projectStage = this.project.project_stage.name;
      let prefix = "";
      let calcClass = "";

      if (projectStage == "Draft") {
        prefix = `${this.$t("GENERAL.PREFIX.CLOSES_ON")}` + ": ";
        calcClass = "success";
      } else if (projectStage == "Recruitment") {
        prefix = `${this.$t("GENERAL.PREFIX.CLOSES_ON")}` + ": ";
        calcClass = "success";
      } else if (projectStage == "Active") {
        prefix = `${this.$t("GENERAL.PREFIX.CLOSES_ON")}` + ": ";
        calcClass = "success";
      } else if (projectStage == "Review") {
        prefix = `${this.$t("GENERAL.PREFIX.CLOSES_ON")}` + ": ";
        calcClass = "success";
      } else if (projectStage == "Closed") {
        prefix = `${this.$t("GENERAL.PREFIX.CLOSED_ON")}` + ": ";
        calcClass = "muted";
      }

      return {
        prefix: prefix,
        class: calcClass
      };
    },
  }
};
</script>
