<template>
  <b-modal size="lg" ref="modal">
    <template #modal-title>
      {{ $t('FORM_HEADERS.PROJECT_TEMPLATE.POST') }}
    </template>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-primary mr-3"
          @click="onCancel"
          :disabled="submissionStates.submitting"
        >
          {{ $t('GENERAL.BUTTONS.CANCEL') }}
        </button>
        <button
          class="btn btn-primary"
          @click="onSubmit"
          :disabled="submissionStates.submitting"
        >
          {{ $t('GENERAL.BUTTONS.SAVE') }}
        </button>
      </div>
    </template>
    <ErrorBox
      v-if="projectTemplateErrors != null"
      :errors="projectTemplateErrors"
      @clear_errors="setProjectTemplateErrors(null)"
    >
    </ErrorBox>
    <div class="p-5">
      <GenericTextInput
        id="input-state"
        class="col-10 mb-10 px-0"
        :model="$v.form.name"
        :label="$t('FORMS.INPUTS.TEMPLATE_NAME')"
        alignLabel="left"
        :placeholder="$t('FORMS.INPUTS.TEMPLATE_NAME')"
        :showInline="false"
        :error="$t('FORMS.ERROR.REQUIRED')"
        type="text"
        :submissionStates="submissionStates"
      />

      <b-form-group class="pt-5 pb-5">
        <b-form-checkbox
          v-for="(option, o) in availableCopyOptions"
          :key="o"
          size="lg"
          v-model="form[option.model]"
          value="1"
          unchecked-value="0"
          switch
          :disabled="submissionStates.submitting || form.system_project_template_type_id === 3"
        >
          <span class="font-size-base pl-1">
            {{ option.text }}
          </span>
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-if="currentUser.system_admin_role_id == 1"
        class="mt-10"
        :label="$t('FORMS.INPUTS.TEMPLATE_SCOPE')"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          v-model="form.system_project_template_type_id"
          :options="availableScopeOptions"
          :aria-describedby="ariaDescribedby"
          size="lg"
          :disabled="submissionStates.submitting"
          stacked
        ></b-form-radio-group>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { 
  mapActions,
  mapMutations,
  mapGetters
} from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Toast from "@/core/classes/Toast.js";

import ErrorBox from "@/view/components/ErrorBox";
import GenericTextInput from "@/modules/together-helpers/components/generic-inputs/GenericTextInput";

export default {
  mixins: [validationMixin],

  name: "CreateProjectTemplate",

  props: {
    project: { type: Object, required: true }
  },

  data() {
    return {
      toast: new Toast(),
      submissionStates: {
        submitting: false
      },
      form: {
        name: "",
        copy_activities: 1,
        copy_styling: 0,
        copy_user_setup: 0,
        system_project_template_type_id: 2
      },
      form_reset: {},
    }
  },

  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    }
  },

  components: {
    ErrorBox,
    GenericTextInput
  },

  mounted() {
    this.cleanup();
    this.form_reset = JSON.parse(JSON.stringify(this.form));
  },

  methods: {
    onCancel: function() {
      this.resetForm();
      this.$refs.modal.hide();
    },
    onSubmit: function() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.submissionStates.submitting = true;

      let payload = {};
      payload.url = (this.form.system_project_template_type_id == 1) ? "api/v1/system_templates" : `api/v1/clients/${this.project.client_id}/project_templates`;
      payload.data = this.form;
      payload.data.based_on = this.project.id;

      this.createProjectTemplate(payload)
        .then(() => {
          if (this.form.system_project_template_type_id == 2) {
            this.toast.notify(this, this.form.name + ' ' + `${this.$t("TOASTS.ADD_PROJECT_TEMPLATE.MESSAGE")}`, `${this.$t("TOASTS.ADD_PROJECT_TEMPLATE.TITLE")}`, 'success');
            this.$router.push({ name: "view_project_templates" });
          } else {
            let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
            let link = `${protocol}${process.env.VUE_APP_ADMIN_URL_SUFFIX}/#/templates/view`;
            window.location.href = link;
          }
        }).finally(() => {
          this.submissionStates.submitting = false;
        })
    },
    cleanup: function() {
      this.setProjectTemplateErrors();
    },
    resetForm: function() {
      this.$v.form.$reset()
      this.form = JSON.parse(JSON.stringify(this.form_reset));
    },
    ...mapActions({
      createProjectTemplate: "createProjectTemplate"
    }),
    ...mapMutations({
      setProjectTemplateErrors: "setProjectTemplateErrors"
    })
  },

  watch: {
    'form.system_project_template_type_id': {
      handler(newTemplateType) {
        if (newTemplateType === 3) {
          this.form.copy_activities = 1;
          this.form.copy_styling = 1;
          this.form.copy_user_setup = 1;          
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      "projectTemplateErrors",
      "currentUser"
    ]),
    availableCopyOptions: function() {
      return [
        { text: `${this.$t("FORMS.INPUTS.TEMPLATE_SETTING_1")}`, model: "copy_activities" },
        { text: `${this.$t("FORMS.INPUTS.TEMPLATE_SETTING_2")}`, model: "copy_styling" },
        { text: `${this.$t("FORMS.INPUTS.TEMPLATE_SETTING_3")}`, model: "copy_user_setup" }
      ]
    },
    availableScopeOptions: function() {
      let scopes = [
        { text: `${this.$t("SYSTEM.SYSTEM_PROJECT_TEMPLATE_TYPE.2")}`, value: 2 },
        { text: `${this.$t("SYSTEM.SYSTEM_PROJECT_TEMPLATE_TYPE.1")}`, value: 1 }
      ];

      if (this.currentUser.is_dev_user === 1) {
        scopes.push({
          text: `${this.$t("SYSTEM.SYSTEM_PROJECT_TEMPLATE_TYPE.3")}`,
          value: 3
        });
      }

      return scopes;
    }
  }
}
</script>