<template>
  <div>
    <ProjectTable
      :projectList="projectList"
      :pageLoader="pageLoader"
      :error="error"
    >
    </ProjectTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import { POPULATE_CLIENT_PROJECT_LIST } from "@/core/services/store/project/project.module";
import ProjectTable from "@/view/content/projects/ProjectTable";

export default {
  name: "Projects",

  components: {
    ProjectTable
  },

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      },
      refreshTimer: null
    };
  },

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      {
        title: this.clientInfo.name,
        route: "/client/projects"
      },
      { title: `${this.$t("NAVIGATION.CLIENT_MENU.PROJECTS")}` }
    ]);
    this.fetchProjects();
    this.refreshTimer = setInterval(() => {
      this.fetchProjects();
    }, 10000)
  },

  beforeDestroy() {
    clearInterval(this.refreshTimer)
  },

  methods: {
    fetchProjects() {
      let payload = {
        clientId: this.clientInfo.id,
        data: {
          params: {
            paginate: false,
            projectStage: "with",
            invitedUsers: "withCount",
            subscribedUsers: "withCount",
            language: "with"
          }
        }
      };
      this.$store
        .dispatch(POPULATE_CLIENT_PROJECT_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project list, please check back later or contact the helpdesk";
        });
    },
  },

  computed: {
    ...mapGetters(["clientInfo", "projectList"])
  }
};
</script>
