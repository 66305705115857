<template>
  <a
    :href="link"
    class="btn btn-icon btn-light btn-hover-success btn-sm mx-1"
    v-b-tooltip.hover.bottom
    :title="$t('TABLES.PROJECT_TOOLTIPS.PROJECT_DASHBOARD')"
  >
    <span class="svg-icon svg-icon-md svg-icon-success">
      <inline-svg src="media/svg/icons/Devices/Diagnostics.svg" title=" " />
    </span>
  </a>
</template>

<script>
export default {
  name: "NavigateToProjectDashboard",

  props: {
    link: { type: String, required: true }
  }
}
</script>