<template>
  <div>
    <div class="col-xxl-12 px-0">
      <div
        class="card card-custom card-stretch gutter-b"
        :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
      >
        <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column col-xxl-5 px-0">
            <div class="form-group mb-0 col-xxl-10 px-0">
              <input
                id="project_table_search"
                v-model="search"
                type="text"
                :placeholder="$t('GENERAL.INPUT.SEARCH')"
                class="form-control form-control-solid h-auto py-3 px-5"
                :disabled="loadingComplete == false"
              />
            </div>
          </h3>
          <div class="card-toolbar">
            <div class="d-flex justify-content-end align-items-center">
              <ProjectStageFilter
                class="py-0 pr-2 mt-0 h-100"
                :filteredStages="filteredStages"
                @onFilterChange="filteredStages = $event"
              />

              <router-link
                to="/client/add_project"
                tag="a"
                class="btn btn-primary py-2 px-4"
                style="font-size:.925rem"
              >
                {{ $t('GENERAL.BUTTONS.ADD_PROJECT') }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body py-0">
          <div class="table-responsive">
            <table
              id="project_table"
              class="table table-head-custom table-vertical center table-hover"
              style="table-layout:auto"
            >
              <thead>
                <tr class="text-left">
                  <th class="px-2" style="min-width:300px">
                    {{ $t('TABLES.PROJECT_HEADERS.NAME') }}
                  </th>
                  <th class="px-2" style="min-width:70px">
                    {{ $t('TABLES.PROJECT_HEADERS.KEY') }}
                  </th>
                  <th class="px-2 text-center" style="min-width:100px">
                    {{ $t('TABLES.PROJECT_HEADERS.STATUS') }}
                  </th>
                  <th class="px-2 text-center" style="min-width:100px">
                    {{ $t('TABLES.PROJECT_HEADERS.USERS') }}
                  </th>
                  <th class="px-2" style="min-width:150px">
                    {{ $t('TABLES.PROJECT_HEADERS.LANGUAGE') }}
                  </th>
                  <th class="px-2" style="min-width:225px">
                    {{ $t('TABLES.PROJECT_HEADERS.DATES') }}
                  </th>
                  <th class="px-2" style="min-width:225px">
                    {{ $t('TABLES.PROJECT_HEADERS.ADMIN_DATE') }}
                  </th>
                  <th class="pr-4 text-right" style="min-width:130px">
                    {{ $t('TABLES.PROJECT_HEADERS.ACTIONS') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(project, i) in calculatedProjectList">
                  <ProjectRow
                    v-if="filteredStages.includes(project.system_project_stage_id)"
                    :key="i"
                    :project="project"
                    :client="clientInfo"
                  >
                  </ProjectRow>
                </template>
                <tr v-if="projectList.length == 0 && loadingComplete">
                  <td class="px-2 text-center" colspan="8">
                    <span class="text-dark-65 font-weight-bold d-block py-3">
                      {{ $t('TABLES.PROJECT_MESSAGES.NO_PROJECTS_TEXT') }}
                      <router-link
                        tag="a"
                        class="text-primary"
                        :to="{ name: 'add_client_project' }"
                      >
                        {{ $t('TABLES.PROJECT_MESSAGES.NO_PROJECTS_LINK') }}
                      </router-link>
                    </span>
                  </td>
                </tr>
                <tr v-if="!loadingComplete && !error.active">
                  <td class="px-2 text-center border-0" colspan="8">
                    <b-spinner
                      variant="primary"
                      label="Spinning"
                      class="table-loader my-3"
                    >
                    </b-spinner>
                  </td>
                </tr>
                <tr v-if="error.active">
                  <td class="px-2 text-center border-0" colspan="8">
                    <b-alert show variant="light">
                      {{ error.message }}
                    </b-alert>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectRow from "@/view/content/projects/ProjectRow.vue";
import ProjectStageFilter from "@/view/components/filters/ProjectStageFilter";

export default {
  name: "ProjectTable",

  props: {
    projectList: { type: Array, required: true },
    pageLoader: { type: Object, required: true },
    error: { type: Object, required: true }
  },

  data() {
    return {
      search: "",
      filteredStages: [1,2,3,4,5]
    };
  },

  components: {
    ProjectRow,
    ProjectStageFilter
  },

  computed: {
    ...mapGetters(["clientInfo"]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },

    calculatedProjectList: function() {
      if (this.search == "") {
        return this.projectList;
      } else {
        let search = this.search.toLowerCase();

        return this.projectList.filter(function( project ) {
          let name = project.name.toLowerCase();
          let key = project.key.toLowerCase();
          let language = project.language.name.toLowerCase();

          return name.includes(search) || key.includes(search) || language.includes(search);
        });
      }
    }
  }
};
</script>
