<template>
  <a
    :href="link"
    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
    v-b-tooltip.hover.bottom
    :title="$t('TABLES.PROJECT_TOOLTIPS.EDIT_PROJECT')"
  >
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg src="media/svg/icons/Design/Edit.svg" title=" " />
    </span>
  </a>
</template>

<script>
export default {
  name: "EditProject",

  props: {
    link: { type: String, required: true }
  }
}
</script>